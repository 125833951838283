@tailwind base;
@tailwind components;
@tailwind utilities;

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: rgb(222, 222, 226);
    margin-bottom: 30px;
  }

  @layer components {
    .theme-custom {
      --color-primary: #259d73;
      --color-primary-light: #5fc8a9;
      --color-primary-dark: #1e7a57;
      --color-secondary: #ffffff;
      --color-secondary-light: #f5f5f5;
      --color-secondary-dark: #cccccc;
      --color-text: #333333;
      --color-text-light: #666666;
      --color-text-dark: #000000;
      --color-background: #ffffff;
      --color-background-alt: #f5f5f5;
      --color-accent: #ff0000;
      /* Tambahkan variabel warna tambahan sesuai kebutuhan */
    }
  }
  
  /* Customisasi tema */
  @layer utilities {
    /* Tambahkan utilitas tambahan sesuai kebutuhan */
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }

